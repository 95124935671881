<template>
    <div class="preview_area" v-if="modelValue">
        <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
        <div class="main_container">
            <div class="file_detailsWpr mt-5">
                <div class="main_area">
                    <div class="image_area">
                        <div class="header">
                            <h4>{{ selectedFile.name }}</h4>
                            <ul>
                                <li><button class="pointer" @click="handleCopy(selectedFile.short_link)"><i class="fas fa-link"></i></button></li>
                                <li><button class="pointer" @click="handleDownloadFile(selectedFile)"><i class="fas fa-download"></i></button></li>
                                <li><button class="pointer danger" v-if="companyUserCan('delete', 'files')" @click="handleDeleteFile(selectedFile.id)"><i class="fas fa-trash-alt"></i></button></li>
                            </ul>
                        </div>
                        <div class="thumb p-0">
                            <iframe v-if="selectedFile.is_doc_file" :src="`https://docs.google.com/gview?url=${selectedFile.path}&embedded=true`" scrolling="yes" frameborder="0"></iframe>
                            <img v-else :src="selectedFile.thumbnail" :class="{ 'file-icon': selectedFile.has_icon }" @error="setDefaultImage" alt="...">
                            <ul class="stat">
                                <li><i class="fas fa-binoculars"></i>{{ selectedFile.views }} Views</li>
                                <li><i class="fas fa-download"></i>{{ selectedFile.downloads }} Downloads</li>
                            </ul>
                        </div>
                    </div>
                    <div class="side_info">
                        <div class="file_option">
                            <h3>File Options</h3>
                            <ul>
                                <li>
                                    <label for="file-download-global" class="switch_option capsule_btn">
                                        <h5>Download Permission</h5>
                                        <input type="checkbox" :true-value="1" :false-value="0" v-model="selectedFile.can_download" id="file-download-global" @change="handleChangePermission({ id: selectedFile.id, permission: 'can_download', value: selectedFile.can_download })" hidden>
                                        <div><span></span></div>
                                    </label>
                                </li>
                                <li>
                                    <label for="file-copy-global" class="switch_option capsule_btn">
                                        <h5>Copy Link Permission</h5>
                                        <input type="checkbox" :true-value="1" :false-value="0" v-model="selectedFile.can_view" id="file-copy-global" @change="handleChangePermission({ id: selectedFile.id, permission: 'can_view', value: selectedFile.can_view })" hidden>
                                        <div><span></span></div>
                                    </label>
                                </li>
                                <li>
                                    <label for="file-view-global" class="switch_option capsule_btn">
                                        <h5>View File Permission</h5>
                                        <input type="checkbox" :true-value="1" :false-value="0" v-model="selectedFile.can_share" id="file-view-global" @change="handleChangePermission({ id: selectedFile.id, permission: 'can_share', value: selectedFile.can_share })" hidden>
                                        <div><span></span></div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div class="file_status">
                            <h3>File Details</h3>
                            <table>
                                <tr>
                                    <th>Type</th>
                                    <td>{{ selectedFile.ext }}</td>
                                </tr>
                                <tr>
                                    <th>Size</th>
                                    <td>{{ selectedFile.size }}</td>
                                </tr>
                                <tr>
                                    <th>Owner</th>
                                    <td>{{ selectedFile.owner }}</td>
                                </tr>
                                <tr>
                                    <th>Modified</th>
                                    <td>{{ moment.utc(selectedFile.created_at).local().format('ll | LT') }} by {{ selectedFile.owner }}</td>
                                </tr>
                                <tr>
                                    <th>Opened</th>
                                    <td>{{ moment.utc(selectedFile.updated_at).local().format('ll | LT') }}</td>
                                </tr>
                                <tr>
                                    <th>Created</th>
                                    <td>{{ moment.utc(selectedFile.created_at).local().format('ll | LT') }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="section_header">
                    <h2>Clients</h2>
                </div>
                <div class="result_wpr new w-100 small m-0">
                    <div class="actions">
                        <ul class="left">
                            <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="page-per-page-filter" />
                            </li>
                        </ul>
                        <ul class="right">
                            <li class="search_area">
                                <input type="text" placeholder="Search" :disabled="usersLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                <button class="search_btn"><i class="fas fa-search"></i></button>
                            </li>
                            <!-- <li class="list_info">
                                {{ fileUsers.from ? fileUsers.from : 0 }} - {{ fileUsers.to ? fileUsers.to : 0 }} of <span>{{ fileUsers.total ? fileUsers.total : 0 }}</span>
                            </li> -->
                        </ul>
                    </div>
                    <table class="show_header standard">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th class="last_action">Last Action</th>
                                <th>Permissions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(user, u) of fileUsers.data" :key="u">
                                <td>{{ user.contact.name }}</td>
                                <td>{{ user.contact.last_action ? user.contact.last_action : '-' }}</td>
                                <td>
                                    <div class="permission_list">
                                        <label :for="`file-view-permission-${user.id}`" class="switch_option capsule_btn">
                                            <h5>View</h5>
                                            <input type="checkbox" :id="`file-view-permission-${user.id}`" :true-value="1" :false-value="0" v-model="user.can_view" @change="handleChangePermission({ id: selectedFile.id, permission: 'can_view', value: user.can_view, contact_ids: user.contact_id })" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <label :for="`file-download-permission-${user.id}`" class="switch_option capsule_btn">
                                            <h5>Download</h5>
                                            <input type="checkbox" :id="`file-download-permission-${user.id}`" :true-value="1" :false-value="0" v-model="user.can_download" @change="handleChangePermission({ id: selectedFile.id, permission: 'can_download', value: user.can_download, contact_ids: user.contact_id })" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <label :for="`file-share-permission-${user.id}`" class="switch_option capsule_btn">
                                            <h5>Share</h5>
                                            <input type="checkbox" :id="`file-share-permission-${user.id}`" :true-value="1" :false-value="0" v-model="user.can_share" @change="handleChangePermission({ id: selectedFile.id, permission: 'can_share', value: user.can_share, contact_ids: user.contact_id })" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>
                            {{ fileUsers.from ? fileUsers.from : 0 }} - {{ fileUsers.to ? fileUsers.to : 0 }} of <span>{{ fileUsers.total ? fileUsers.total : 0 }}</span>
                        </li>
                    </ul>
                </div>
                <div class="pagination" v-show="fileUsers.total">
                    <pagination v-model="params.page" :pages="fileUsers.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { mapState, mapGetters, mapActions } from 'vuex'

    import moment from 'moment'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'File Preview',

        data () {
            return {
                selectedFile: {},
                moment,
                params: {
                    page: 1,
                    per_page: 10,
                    search: '',
                    field: 'id',
                    order: 'desc',
                    file_id: 0,
                },
                isTyping: false,
                fileUsers: {},
            };
        },

        props: {
            modelValue: Boolean,
            file: Object,
            refreshFiles: {
                type: Function,
                default: () => {}
            }
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.params.file_id = vm.file.id;
                    vm.params.page    = 1;
                    vm.params.search  = '';
                    vm.selectedFile   = JSON.parse(JSON.stringify(vm.file));

                    vm.updateStats({ id: vm.file.id, stats: 'views' }).then((resp) => {
                        vm.refreshFiles(true);
                        vm.selectedFile.views += 1;
                    });
                    vm.getFileUsers(vm.params);
                }
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getFileUsers(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getFileUsers(vm.params);
                        }
                    }
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;

                vm.getFileUsers(vm.params);
            },

            users (users) {
                const vm = this;

                vm.fileUsers = JSON.parse(JSON.stringify(users));
            },
        },

        computed: {
            ...mapState({
                users: state => state.fileModule.fileUsers,
                usersLoader: state => state.fileModule.usersLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        methods: {
            ...mapActions({
                updateStats: 'fileModule/updateStats',
                deleteFiles: 'fileModule/deleteFiles',
                downloadFile: 'fileModule/downloadFile',
                changePermission: 'fileModule/changePermission',
                getFileUsers: 'fileModule/getFileUsers',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            setDefaultImage (event) {
                event.target.src = require('@/assets/images/thumb/default-cover-3-2.svg');
            },

            handleDeleteFile (id) {
                const vm = this;

                vm.deleteFiles([id]).then((result) => {
                    if (result) {
                        vm.refreshFiles();
                        vm.closeModal();
                    }
                });
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                })
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['page-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['page-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page    = page;

                vm.getFileUsers(vm.params);
            },

            handleDownloadFile (file) {
                const vm = this;

                vm.downloadFile(file).then((result) => {
                    if (result) {
                        vm.refreshFiles(true);
                        vm.selectedFile.downloads += 1;
                    }
                });
            },

            handleChangePermission (params) {
                const vm = this;

                vm.changePermission(params).then((result) => {
                    if (result) {
                        vm.getFileUsers(vm.params);
                        vm.refreshFiles(true);
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .main_container {
        max-width: 1170px;
        padding: 0 10px;
        margin: 0 auto;
        text-align: left;
    }

    .file_detailsWpr {
        max-width: 100%;
        max-height: 100%;
        flex-direction: column;
        align-items: stretch;
    }

    .file_detailsWpr .thumb img {
        height: 370px;
    }

    .file_detailsWpr h3 {
        margin-bottom: 10px;
    }

    .main_area {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px 40px -15px;
    }

    .image_area {
        width: 65%;
        padding: 0 15px;
    }

    .side_info {
        width: 35%;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
    }

    .side_info .file_option,
    .side_info .file_status {
        width: 100%;
        padding: 10px 0;
    }
    .result_wpr.new .actions{
        margin: 0 0 10px 0;
    }
    .result_wpr table.standard td:first-child,
    .result_wpr table.standard th:first-child {
        width: auto;
        text-align: left;
        padding-left: 15px;
    }

    .result_wpr table.standard td:last-child,
    .result_wpr table.standard th:last-child {
        text-align: right;
        width: 400px;
    }
    .result_wpr.small table th:not(:last-child){
        min-width: 100px;
    }
    .file_detailsWpr .section_header h2 {
        font-size: 30px !important;
        line-height: 36px !important;
    }

    .permission_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 5px 0;
    }

    /* .permission_list .switch_option {
        transform: scale(0.8);
    } */
    .permission_list .capsule_btn{
        margin: 0 0 0 15px;
        padding: 5px 0;
        border: 0;
    }
    .permission_list .capsule_btn div {
        flex: 0 0 22px;
        width: 22px;
        height: 12px;
    }
    .permission_list .capsule_btn div span {
        left: 1px;
        top: 1px;
        width: 8px;
        height: 8px;
    }
    .permission_list .switch_option h5{
        font-size: 11px !important;
        line-height: 14px !important;
        margin-right: 10px;
    }

    .preview_area{
        background: #f5f5f5;
    }
    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 1;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }

    @media(max-width: 767px){
        .file_detailsWpr .main_area{
            flex-direction: column;
        }
        .file_detailsWpr .main_area .image_area, .file_detailsWpr .main_area .side_info{
            width: 100%;
        }
        .file_detailsWpr .inner_pages .result_wpr table, .file_detailsWpr .inner_pages .result_wpr .actions{
            min-width: 600px !important;
        }
        .file_detailsWpr .inner_pages .result_wpr .actions > ul > li.optionDrops.sort_list {
            border-radius: 14px 0 0 0 !important;
        }
    }
    @media(max-width: 599px){
        .file_detailsWpr{
            padding: 15px 10px 30px;
        }
    }
    @media(max-width: 450px){
        .result_wpr.new .actions > ul li.search_area input, .result_wpr.new .actions > ul li.search_area .search_btn{
            height: 30px;
        }
    }
</style>
